import Axios from "axios";
import mockPlans from '@/components/planPicker/mockPlans.json'

/*
 * Constantes
 */
export const RECURRING_TYPE = Object.freeze({
    MONTHLY: 'monthly',
    NO: 'no'
})
export const LOYALTY_TYPE = Object.freeze({
    ANNUAL: 'annual',
    NO: 'no'
})
export const CLASS_TYPE = Object.freeze({
    DEFAULT: 'default',
    _10X15: '10x15',
    _15X20: '15x20',
    IMA: 'ima'
})
export const FILTER_TYPE = Object.freeze({
    RECURRING: 'recurring',
    LOYALTY: 'loyalty',
    CLASS: 'class',
    QUANTITY: 'quantity'
})

/*
 * Helpers
 */
const defaultGroupObject = () => ({
    plans: [],
    minPrice: 1e6,
    extraPhotos: false
})
const groupOrder = {
    [FILTER_TYPE.RECURRING]: [RECURRING_TYPE.MONTHLY, RECURRING_TYPE.NO],
    [FILTER_TYPE.LOYALTY]: [LOYALTY_TYPE.ANNUAL, LOYALTY_TYPE.NO],
    [FILTER_TYPE.CLASS]: [CLASS_TYPE.DEFAULT, CLASS_TYPE._10X15, CLASS_TYPE._15X20, CLASS_TYPE.IMA],
    [FILTER_TYPE.QUANTITY]: 'numeric'
}
const groupPlansByType = (filteredPlans, getType) => {
    const group = {}
    filteredPlans.forEach(plan => {
        const type = getType(plan)
        if (!group[type]) group[type] = defaultGroupObject()
        group[type].plans.push(plan)
        group[type].minPrice = Math.min(group[type].minPrice, plan.price)
        if (plan.config?.extra_photos > 0) {
            group[type].extraPhotos = true
        }
    })
    return group
}
const orderGroupedPlans = (groupedPlans, filterType) => {
    const orderedGroup = {}
    const order = groupOrder[filterType]
    if (order === 'numeric') {
        const sortedKeys = Object.keys(groupedPlans)
          .map(key => Number(key))
          .sort((a, b) => a - b)
        sortedKeys.forEach(key => {
            orderedGroup[key] = groupedPlans[key]
        })
        return orderedGroup
    }
    order.forEach(type => {
        if (groupedPlans[type]) {
            orderedGroup[type] = groupedPlans[type]
        }
    })
    return orderedGroup
}

/*
 * Vuex
 */
export const state = () => ({
    plans: [],
    filters: {
        [FILTER_TYPE.RECURRING]: null,
        [FILTER_TYPE.LOYALTY]: null,
        [FILTER_TYPE.CLASS]: null,
        [FILTER_TYPE.QUANTITY]: null
    }
})
export const getters = {
    filteredPlans(state) {
        let filteredPlans = state.plans
        const filters = Object.values(state.filters)
        filters.forEach(filter => {
            if (filter) filteredPlans = filteredPlans.filter(filter)
        })
        return filteredPlans
    },
    /*
     * Objeto {uuid: photosQuantity}
     */
    photosQuantityObject(state) {
        return state.plans.reduce((accumulator, plan) => {
            const extraPhotos = 0
            accumulator[plan.uuid] = plan.photos + extraPhotos
            return accumulator
        }, {})
    },
    /*
     * Objeto {uuid: photosQuantity}
     */
    photosQuantityWithExtrasObject(state) {
        return state.plans.reduce((accumulator, plan) => {
            const extraPhotos = plan.config?.extra_photos || 0
            accumulator[plan.uuid] = plan.photos + extraPhotos
            return accumulator
        }, {})
    },
    availableRecurringTypes(state, getters) {
        const group = groupPlansByType(
          getters.filteredPlans,
          plan => ['monthly', 'loyalty'].includes(plan.type) ? RECURRING_TYPE.MONTHLY : RECURRING_TYPE.NO
        )
        return orderGroupedPlans(group, FILTER_TYPE.RECURRING)
    },
    availableLoyaltyTypes(state, getters) {
        const group = groupPlansByType(
          getters.filteredPlans,
          plan => ['loyalty'].includes(plan.type) ? LOYALTY_TYPE.ANNUAL : LOYALTY_TYPE.NO
        )
        return orderGroupedPlans(group, FILTER_TYPE.LOYALTY)
    },
    availableClassTypes(state, getters) {
        const group = groupPlansByType(
          getters.filteredPlans,
          plan => plan.config.collection_config?.class?.[0]
        )
        return orderGroupedPlans(group, FILTER_TYPE.CLASS)
    },
    availableQuantityTypes(state, getters) {
        const group = groupPlansByType(
          getters.filteredPlans,
          plan => getters.photosQuantityObject[plan.uuid]
        )
        return orderGroupedPlans(group, FILTER_TYPE.QUANTITY)
    },
    minPrice(state, getters) {
        return Math.min(...getters.filteredPlans.map(plan => plan.price));
    },
    isRecurring(state, getters) {
        return Object.keys(getters.availableRecurringTypes).some(type => type === RECURRING_TYPE.MONTHLY)
    }
}
export const actions = {
    getPlans({commit}) {
        return new Promise(async (resolve, reject) => {
            try {
                setTimeout(() => {
                    commit('setPlans', {plans: mockPlans})
                    resolve()
                }, 100)

                // const response = await Axios.get('/getplans')
                // if (response.data.success) {
                //     const plans = response.data.data
                //     commit('setPlans', {plans})
                //     resolve()
                // }
            } catch (e) {
                console.error('loadPlans error:', e)
                reject()
            }
        })
    },
    addFilter({commit}, {filterType, callback}) {
        commit('addFilter', {filterType, callback})
    },
    removeFilter({commit}, {filterType}) {
        commit('removeFilter', {filterType})
    },
    filterRecurring({dispatch}, {value}) {
        const callback = (plan) => {
            switch (value) {
                case RECURRING_TYPE.MONTHLY:
                    return ['monthly', 'loyalty'].includes(plan.type)
                case RECURRING_TYPE.NO:
                    return ['one-time-buy'].includes(plan.type)
            }
        }
        dispatch('addFilter', {filterType: FILTER_TYPE.RECURRING, callback})
    },
    filterLoyalty({dispatch}, {value}) {
        const callback = (plan) => {
            switch (value) {
                case LOYALTY_TYPE.ANNUAL:
                    return plan.type === 'loyalty'
                case LOYALTY_TYPE.NO:
                    return plan.type !== 'loyalty'
            }
        }
        dispatch('addFilter', {filterType: FILTER_TYPE.LOYALTY, callback})
    },
    filterClass({dispatch}, {value}) {
        const callback = (plan) => {
            return plan.config.collection_config?.class?.[0] === value
        }
        dispatch('addFilter', {filterType: FILTER_TYPE.CLASS, callback})
    },
    filterPhotosQuantity({dispatch, getters}, {value}) {
        const callback = (plan) => {
            return getters.photosQuantityObject[plan.uuid] === parseInt(value)
        }
        dispatch('addFilter', {filterType: FILTER_TYPE.QUANTITY, callback})
    },
}
export const mutations = {
    setPlans(state, {plans}) {
        state.plans = plans
    },
    addFilter(state, {filterType, callback}) {
        state.filters[filterType] = callback
    },
    removeFilter(state, {filterType}) {
        state.filters[filterType] = null
    }
}
export const planPicker = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}